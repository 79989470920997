import React from 'react';
import Responsive from 'react-responsive';

export const DesktopDisplay = (props) => (
	<Responsive {...props} minWidth={992} />
);
export const NonDesktopDisplay = (props) => (
	<Responsive {...props} maxWidth={991} />
);
export const TabletDisplay = (props) => (
	<Responsive {...props} minWidth={768} maxWidth={991} />
);
export const MobileDisplay = (props) => (
	<Responsive {...props} maxWidth={767} />
);
export const DefaultDisplay = (props) => (
	<Responsive {...props} minWidth={768} />
);
