import React, { useRef } from 'react';
import NavbarItems from '../molecules/NavbarItems';
import styled from '@emotion/styled';

const StyledNavContainer = styled.div`
	max-height: 62px;
`;

const NavbarDesktop = ({
	setDropdownOpen,
	dropdownOpen,
	toggleDropdownOpen,
	promoBadge,
	isAccountPage,
	currentPage,
	userName,
	navItems,
}) => {
	const shopRef = useRef();
	return <NavbarItems navItems={navItems} />;
};

export default NavbarDesktop;
