import React, { Fragment, createContext } from 'react';

export const PageLayerContext = createContext({}, () => {});

export const PageLayerProvider = (props) => {
	return (
		<Fragment>
			{/* eslint-disable-next-line no-undef */}
			<PageLayerContext.Provider value={pageLayer[0]}>
				{props.children}
			</PageLayerContext.Provider>
		</Fragment>
	);
};
