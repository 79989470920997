import { useEffect } from 'react';

function useOnClickOutside(ref, handler, linkRef) {
	useEffect(
		() => {
			const listener = (event) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}

				//Do nothing if clicking linkRef's element or descendent elements
				if (typeof linkRef !== 'undefined') {
					if (!linkRef.current || linkRef.current.contains(event.target)) {
						return;
					}
				}

				handler(event);
			};

			document.addEventListener('click', listener);

			return () => {
				document.removeEventListener('click', listener);
			};
		},
		// Add ref and handler to effect dependencies
		// It's worth noting that because passed in handler is a new ...
		// ... function on every render that will cause this effect ...
		// ... callback/cleanup to run every render. It's not a big deal ...
		// ... but to optimize you can wrap handler in useCallback before ...
		// ... passing it into this hook.
		[ref, handler, linkRef]
	);
}

export default useOnClickOutside;
