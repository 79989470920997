import { useState } from 'react';

const useSubmenuOpen = (initialValue) => {
	const [subMenuOpen, setSubMenuOpen] = useState(initialValue);

	const handleEnter = () => {
		setSubMenuOpen(true);
		document.querySelector('.navbar-app-overlay').style = 'display: block';
	};

	const handleLeave = () => {
		setSubMenuOpen(false);
		document.querySelector('.navbar-app-overlay').style = 'display: none';
	};

	return [subMenuOpen, setSubMenuOpen, handleEnter, handleLeave];
};

export default useSubmenuOpen;
