import styled from '@emotion/styled';
import React, { useState } from 'react';
import useSubmenuOpen from '../../hooks/useSubmenuOpen';
import CMSDesktopMenuDropdown from './CMSDesktopMenuDropdown';
import { DesktopDisplay, TabletDisplay } from '../../util/Breakpoint';

const StyledNavAnchor = styled.a`
	text-align: left !important;
	font-size: 14px !important;
	.wsj & {
		font-family: Arial, sans-serif;
		color: #888 !important;
	}
	.osw & {
		font-family: ${({ theme }) => theme?.fonts?.primary};
		color: #222;
	}
	font-weight: 700 !important;
	text-transform: uppercase;
	line-height: 26px !important;
	letter-spacing: 1.8px;
`;
const StyledNavItems = styled.div`
	.dropdown {
		display: flex;
		align-items: center;
		height: 100%;
		.wsj & {
			border-bottom: 3px solid #ffffff;
		}
		.osw & {
			border-bottom: 3px solid #ededed;
		}
		&:hover {
			.wsj & {
				border-bottom: 3px solid #e92440 !important;
			}
			.osw & {
				border-bottom: 3px solid #890419 !important;
			}
		}
	}
`;
const NavbarItemMembership = ({
	active,
	ref,
	onMouseLeave,
	onMouseEnter,
	toggleMenu,
	dropDownData,
	setHandleTabletDropDown,
	showDropDown,
}) => {
	const [tabletDropDownOpen, setTabletDropDownOpen] = useState(false);
	const [
		membershipOpen,
		setMembershipOpen,
		handleMembershipEnter,
		handleMembershipLeave,
	] = useSubmenuOpen(false);

	const highlightNavMenuItem =
		window.innerWidth < 992 ? showDropDown : membershipOpen;

	const handleOnClick = () => {
		setHandleTabletDropDown((prevState) => {
			let updatedState = prevState?.map((element) => {
				let temp = element;
				if (element?.title === dropDownData?.heading) {
					temp.show = !element?.show;
					return temp;
				} else {
					temp.show = false;
					return temp;
				}
			});

			let showOverlay = updatedState.some((ele) => {
				return ele.show === true;
			});
			if (showOverlay) {
				document.querySelector('.navbar-app-overlay').style = 'display: block';
			} else {
				document.querySelector('.navbar-app-overlay').style = 'display: none';
			}
			return updatedState;
		});
	};
	return (
		<StyledNavItems
			onMouseEnter={handleMembershipEnter}
			onMouseLeave={handleMembershipLeave}
		>
			<li
				className={
					'dropdown' + (highlightNavMenuItem ? ' nav-menu-drop-down' : '')
				}
			>
				<StyledNavAnchor
					href='#'
					class='nav-main dropdown-toggle'
					data-toggle='dropdown'
					style={{
						cursor: 'pointer',
						textDecoration: 'none',
					}}
					onClick={() => {
						handleOnClick();
					}}
				>
					{dropDownData?.heading}
				</StyledNavAnchor>
			</li>
			<DesktopDisplay>
				<CMSDesktopMenuDropdown
					isOpen={membershipOpen}
					onMouseEnter={handleMembershipEnter}
					onMouseLeave={handleMembershipLeave}
					content={dropDownData}
				/>
			</DesktopDisplay>
			<TabletDisplay>
				<CMSDesktopMenuDropdown isOpen={showDropDown} content={dropDownData} />
			</TabletDisplay>
		</StyledNavItems>
	);
};

export default NavbarItemMembership;
