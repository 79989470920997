import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider, { slickNext, slickPrev } from 'react-slick';
import setEventTracking from '../../util/setEventTracking';
import styled from '@emotion/styled';

const SimpleSlider = styled(Slider)`
	margin: 0 auto;
	&.slick-slider{
		width: 15vw;
		margin: 0;
		margin-top: 30px;
	
	}
	.carsoule-image{
		display: flex;
		margin: auto;
	}
	.mixed{
		width:65%;
	}
	.single{
		width:15%;
	}
	.img-container{
		width: 100%!important;
		a{
			color: #000;
			&:hover{
				color: #0274a9;
				text-decoration: underline!important;
			}
		}
		
	}
	.product-name{
		text-align: center;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		margin-top: 32px;
		font-family: Arial, sans-serif;
	}
	.product-price{
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		text-align: center;
		margin-top: 10px;
		font-family: Georgia, sans-serif;
	}
	.slick-prev:before{
		display:none;
	}
	.slick-next:before{
		display:none;
	}
	.slick-prev{
    	left: 10px;
		top: 40%;
	}
	}
	.slick-next{
		right: 5px;
		top: 40%;
	}
	}
	.@media (max-width: 1300px) {
		.slick-next{
			left: 0;
		}
		.img-container{
			width: 100%!important;
		}
	}
`;
const ArrowWrapper = styled.div`
	color: #000000;
	align-self: center;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.65);
`;

const Arrow = ({ innerText, onClick, className }) => {
	return (
		<ArrowWrapper onClick={onClick} className={className}>
			<div>{innerText}</div>
		</ArrowWrapper>
	);
};

const TrendingProducts = ({ productsDetails, menuHeading, subHeading }) => {
	const [trendingProducts, setTrendingProducts] = useState([]);
	const [isPngLoading, setIsPngLoading] = useState(
		menuHeading !== 'MIXED CASES'
	);

	useEffect(() => {
		(async function () {
			try {
				const productsUrl = [];
				productsDetails.forEach((item) => {
					if (item?.fields?.itemCode) {
						productsUrl.push(
							axios.get(`/api/product/item/${item?.fields?.itemCode}`)
						);
					}
				});

				const res = await Promise.allSettled(productsUrl);
				const productData = [];
				res.forEach((response) => {
					if (response?.status === 'fulfilled') {
						productData.push(response?.value?.data?.response);
					}
				});
				setTrendingProducts(productData);
			} catch {
				throw Error('Promise failed');
			}
		})();
	}, []);
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: (
			<Arrow
				innerText={
					<img
						src={'/images/us/common/icons/wsj/angle_right_black.svg'}
						height='24px'
						width='10px'
						alt='right'
						onClick={slickNext}
					/>
				}
				className='right'
			/>
		),
		prevArrow: (
			<Arrow
				innerText={
					<img
						src={'/images/us/common/icons/wsj/angle_left_black.svg'}
						height='24px'
						width='10px'
						alt='left'
						onClick={slickPrev}
					/>
				}
				className='left'
			/>
		),
	};
	return (
		<>
			{trendingProducts && (
				<SimpleSlider {...settings}>
					{trendingProducts.map((product) => {
						return (
							<div className='img-container'>
								<a
									href={`/product/${product?.name}/${product?.itemCode}`}
									onClick={() => {
										setEventTracking(
											menuHeading,
											subHeading,
											'Click',
											product?.name
										);
									}}
								>
									{menuHeading !== 'MIXED CASES' && (
										<img
											src={product?.thumbnailImage.split('_')[0].concat('.png')}
											alt='Bottle_image'
											className={
												menuHeading === 'MIXED CASES'
													? 'carsoule-image mixed'
													: 'carsoule-image single'
											}
											style={{ display: isPngLoading ? 'flex' : 'none' }}
											onError={(e) => setIsPngLoading(false)}
										/>
									)}
									<img
										src={product?.thumbnailImage}
										alt='Bottle_image'
										className={
											menuHeading === 'MIXED CASES'
												? 'carsoule-image mixed'
												: 'carsoule-image single'
										}
										style={{ display: isPngLoading ? 'none' : 'flex' }}
										onError={(e) => {
											e.target.src =
												menuHeading === 'MIXED CASES'
													? '/images/us/wsj/common/image_unavailable/case_unavailable.png'
													: '/images/us/wsj/common/image_unavailable/bottle_unavailable_100x240.png';
										}}
									/>

									<div className='product-name'>{product?.name}</div>
									<div className='product-price'>
										${product?.skus[0]?.salePrice}
									</div>
								</a>
							</div>
						);
					})}
				</SimpleSlider>
			)}
		</>
	);
};

export default TrendingProducts;
