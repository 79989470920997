import styled from '@emotion/styled';
import React, { useState } from 'react';
import NavigationDesktopDropdownMenu from './NavigationDesktopDropdownMenu';
import { DesktopDisplay, TabletDisplay } from '../../util/Breakpoint';
import NavbarAccordionMenu from './NavbarAccordionMenu';

const StyledNavAnchor = styled.a`
	text-align: left !important;
	font-size: 14px !important;
	.wsj & {
		font-family: Arial, sans-serif;
		color: #888 !important;
	}
	.osw & {
		font-family: ${({ theme }) => theme?.fonts?.primary};
		color: #222;
	}
	font-weight: 700 !important;
	text-transform: uppercase;
	line-height: 26px !important;
	letter-spacing: 1.8px;
`;
const StyledNavItems = styled.div`
	.dropdown {
		display: flex;
		align-items: center;
		height: 100%;
		.wsj & {
			border-bottom: 3px solid #ffffff;
		}
		.osw & {
			border-bottom: 3px solid #ededed;
		}
		&:hover {
			.wsj & {
				border-bottom: 3px solid #e92440 !important;
			}
			.osw & {
				border-bottom: 3px solid #890419 !important;
			}
		}
	}
`;
const NavbarItemShop = ({
	active,
	toggleDropdownOpen,
	shopRef,
	onMouseEnter,
	onMouseLeave,
	dropDownData,
	setHandleTabletDropDown,
	showDropDown,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [tabletDropDownOpen, setTabletDropDownOpen] = useState(false);

	const highlightNavMenuItem =
		window.innerWidth < 992 ? showDropDown : dropdownOpen;

	const handleMouseEnter = () => {
		setDropdownOpen(true);
		document.querySelector('.navbar-app-overlay').style = 'display: block';
	};
	const handleMouseLeave = () => {
		setDropdownOpen(false);
		document.querySelector('.navbar-app-overlay').style = 'display: none';
	};
	return (
		<StyledNavItems
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className={
				'd-flex align-items-center nav-font cy-shop-nav-link  nav-items' +
				(active ? 'border-bottom border-dark' : '')
			}
		>
			<li
				className={
					'dropdown' + (highlightNavMenuItem ? ' nav-menu-drop-down' : '')
				}
			>
				<StyledNavAnchor
					href='#'
					class='nav-main dropdown-toggle'
					data-toggle='dropdown'
					onClick={() => {
						setHandleTabletDropDown((prevState) => {
							let updatedState = prevState?.map((element) => {
								let temp = element;
								if (element?.title === dropDownData?.heading) {
									temp.show = !element?.show;
									return temp;
								} else {
									temp.show = false;
									return temp;
								}
							});

							let showOverlay = updatedState.some((ele) => {
								return ele.show === true;
							});
							if (showOverlay) {
								document.querySelector('.navbar-app-overlay').style =
									'display: block';
							} else {
								document.querySelector('.navbar-app-overlay').style =
									'display: none';
							}
							return updatedState;
						});
					}}
				>
					{dropDownData?.heading}
				</StyledNavAnchor>
			</li>
			<DesktopDisplay>
				<NavigationDesktopDropdownMenu
					isOpen={dropdownOpen}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					dropDownData={dropDownData?.navItems}
					footerLink={dropDownData?.link}
					menuHeading={dropDownData?.heading}
				/>
			</DesktopDisplay>
			<TabletDisplay>
				<NavbarAccordionMenu
					setDropdownOpen={setTabletDropDownOpen}
					dropdownOpen={showDropDown}
					shopRef={shopRef}
					navItems={dropDownData?.navItems}
					footerLink={dropDownData?.link}
					mainHeading={dropDownData?.heading}
				/>
			</TabletDisplay>
		</StyledNavItems>
	);
};

export default NavbarItemShop;
