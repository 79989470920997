/**
 * setEventTracking - Used to set ga events
 * Pass in object containing named key / value pairs
 * @param {Object} eventObject
 */
const setEventTracking = (category, subCategory, action, label) => {
	return dataLayer.push({
		event: 'MainNav',
		eventCategory: category,
		eventSubCategory: subCategory,
		eventAction: action,
		eventLabel: label,
	});
};

export default setEventTracking;
