import styled from '@emotion/styled';
import React, { useState } from 'react';
import ImagePlaceholder from '../atom/ImagePlaceholder';
import getColor from '../../util/getColor';
import setEventTracking from '../../util/setEventTracking';
import { EmotionBreakpoint } from 'dw-us-ui';

const RowWrapper = styled.div`
	.d-flex {
		display: flex;
	}
	.flex-column {
		flex-direction: column;
	}
	&.menu-collapsed {
		display: none;
	}
	width: 100%;
	z-index: 1000;
	position: absolute;
	left: 0;
	.menu-position-inner {
		max-width: 1920px;
		background: white;
		padding: 0 1.5rem;
		font-size: 14px;
		&.align-center {
			justify-content: center;
			align-items: center;
		}
	}
	h2 {
		color: #ffffff;
	}
	.membershipTitles {
		color: #fff;
		.wsj & {
			font-size: 20px;
		}
		.osw & {
			font-size: 16px;
			color: #222;
			font-family: 'Neue-haas-grotesk', sans-serif;
		}
		text-transform: none;
		font-weight: bold;
		margin-bottom: 0.5rem;
		line-height: 1.15;
	}
	.membershipDescription {
		color: #fff;
		.osw & {
			color: #222;
			font-family: ${({ theme }) => theme?.fonts?.primary};
			font-size: 14px;
			line-height: 130%; /* 18.2px */
		}
	}
	.cardsWrapper {
		text-align: left;
		a:hover {
			text-decoration: underline !important;
			.osw & {
				color: #222 !important;
			}
		}
		max-width: 65em;

		@media (min-width: 75em) {
			max-width: 1200px;
		}

		@media (min-width: 90em) {
			max-width: 1440px;
		}

		@media (min-width: 120em) {
			max-width: 1680px;
		}
		.osw & {
			justify-content: center;
			width: 100%;
		}
	}
	.tabletImageStyle {
		height: 160px;

		@media (min-width: 75em) {
			height: 180px;
		}

		@media (min-width: 90em) {
			height: 220px;
		}

		@media (min-width: 120em) {
			height: 270px;
		}
	}
	.tabletDescriptionStyle {
		.wsj & {
			height: 168px;
		}
		.osw & {
			height: 98px;
		}
		.tabletDescriptionContainer {
			.wsj & {
				padding: 1.5rem;
			}
			.osw & {
				padding: 24px 16px 9px 16px;
			}
			@media (max-width: 1024) {
				padding: 1rem;
			}
		}
		@media (min-width: 60em) {
			height: 180px;
		}

		@media (min-width: 75em) {
			height: 150px;
		}

		@media (min-width: 120em) {
			height: 110px;
		}
	}
	.subHeadings {
		white-space: nowrap;
		@media (max-width: 1025px) {
			white-space: normal;
		}
	}
	.membershipCategories {
		.osw & {
			font-size: 18px;
			font-weight: 700;
			font-family: 'Neue-haas-grotesk', sans-serif;
		}
	}
	.mr-3 {
		.wsj & {
			margin-right: 1rem !important;
		}
		.osw & {
			margin-right: 1.5rem !important;
		}
	}
	.border-bottom {
		.wsj & {
			border-bottom: 1px solid #dee2e6 !important;
		}
		.osw & {
			border-bottom: 1px solid #ededed !important;
		}
	}
	.py-2 {
		.wsj & {
			padding-top: 8px;
			padding-bottom: 8px;
		}
		.osw & {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.my-3 {
		margin-bottom: 2rem !important;
		margin-top: 2rem !important;
	}
	.nav-cards {
		margin-top: 16px !important;
		.wsj & {
			margin-bottom: 16px !important;
		}
		.osw & {
			margin-bottom: 32px !important;
		}
	}
`;

const ContentDescription = styled.div`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	color: #000000;
	text-transform: 'none';
	text-align: 'center';
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
	.osw & {
		font-family: ${({ theme }) => theme?.fonts?.primary};
	}
`;

const GroupHeading = styled.h5`
	text-transform: none;
	font-weight: bold;
	.wsj & {
		color: ${({ fontColor }) => fontColor};
		font-family: Georgia, sans-serif;
	}
	.osw & {
		font-family: ${({ theme }) => theme?.fonts?.subHeader};
		color: #222;
	}
`;

const CMSDesktopMenuDropdown = ({
	onMouseEnter,
	onMouseLeave,
	isOpen,
	content,
}) => {
	if (content) {
		return (
			<RowWrapper
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={` ${isOpen ? 'expand menu-position' : 'menu-collapsed'}`}
			>
				<div className='d-flex flex-column menu-position-inner w-100 align-center'>
					{content?.description && (
						<ContentDescription className='my-3'>
							{content?.description}
						</ContentDescription>
					)}
					<div className='cardsWrapper d-flex border-bottom align-center'>
						{content?.navItems?.map(({ fields }, index) => {
							return (
								<div
									key={index}
									className={
										content?.navItems?.length - 1 !== index ? 'mr-3' : ''
									}
									style={{ width: `${fields?.navItems?.length * 25}%` }}
								>
									<div className='border-bottom subHeadings'>
										<GroupHeading
											className='px-0 py-2 membershipCategories'
											style={{
												marginTop:
													index !== content?.navItems?.length - 1 &&
													window.innerWidth <= 1025
														? '20px'
														: '',
											}}
											fontColor={getColor(fields?.background) || '#000'}
										>
											{fields.heading}
										</GroupHeading>
									</div>
									<div className='d-flex flex-nowrap nav-cards'>
										{fields.navItems.map(({ fields: node }, i) => (
											<NavCards
												nav={node}
												navItems={fields.navItems}
												index={i}
												key={i}
												subHeading={fields.heading}
												mainHeading={content?.heading}
											/>
										))}
									</div>
								</div>
							);
						})}
					</div>
					{content?.footerlink?.fields?.url && (
						<div className='d-flex flex-column text-center border-bottom'>
							<h5>
								<a
									style={{
										textTransform: 'none',
									}}
									href={content?.footerlink?.fields?.url}
								>
									{content?.footerlink?.fields?.text}
								</a>
							</h5>
						</div>
					)}
				</div>
			</RowWrapper>
		);
	} else {
		return null;
	}
};

const NavCards = ({ nav, index, navItems, subHeading, mainHeading }) => {
	const [loaded, setLoaded] = useState(false);

	return (
		<a
			key={index}
			className={navItems.length - 1 !== index ? 'mr-3' : ''}
			href={nav?.link?.fields?.url}
			style={{ flex: '1' }}
			onClick={() => {
				setEventTracking(
					mainHeading,
					subHeading,
					nav.gaEventAction,
					`${nav.heading}`
				);
			}}
		>
			{!loaded ? <ImagePlaceholder height={'265px'} /> : null}
			<img
				className='tabletImageStyle'
				alt={nav.heading}
				onLoad={() => setLoaded(true)}
				src={
					nav?.media?.fields?.image?.fields?.file?.url ||
					nav?.media?.fields?.imagePath
				}
				style={{
					width: '100%',
					display: !loaded ? 'none' : 'initial',
				}}
			/>
			<div
				style={{
					backgroundColor: getColor(nav.background),
				}}
				className='tabletDescriptionStyle'
			>
				<div className='p-4 tabletDescriptionContainer'>
					<h3 className='m-0 mb-2 membershipTitles'>{nav.heading}</h3>
					<p className='m-0 mb-2 membershipDescription'>{nav.description}</p>
				</div>
			</div>
		</a>
	);
};

export default CMSDesktopMenuDropdown;
