// Required Libs

var brandUtil = {
	getBrand() {
		var brandArr = [
			'laithwaites',
			'virgin',
			'wsjwine',
			'theaustralianwine',
			'winepeople',
			'tcmwineclub',
			'natgeowine',
			'nprwineclub',
			'bhgwine',
			'macyswinecellar',
			'velocity',
			'directwines',
			'directwinesHK',
			'omahasteakswine'
		];
		var brand = brandArr.filter((elem) => {
			return location.host.indexOf(elem) > 0;
		});
		return location.host.includes('redemption') ? 'velocity' : brand[0];
	},
	getCountry() {
		if (location.host.indexOf('co.uk') > 0) {
			return 'uk';
		} else if (location.host.indexOf('com.au') > 0) {
			return 'au';
		} else if (location.host.indexOf('co.nz') > 0) {
			return 'nz';
		} else if (location.host.indexOf('com.tw') > 0) {
			return 'tw';
		} else if (location.host.indexOf('com.hk') > 0) {
			return 'hk';
		} else {
			return 'us';
		}
	},
	getEnv() {
		if (location.host.indexOf('webdev00') > -1 ||
			location.host.indexOf('st.') > -1 ||
			location.host.indexOf('uat.') > -1) {
			return 'uat';
		} else {
			return 'live';
		}
	},
	content(brand) {
		var data = {};
		let phoneNumber;
		if (brand === 'nprwineclub')
			{
				phoneNumber = '1-833-677-9463';
			} else {
				phoneNumber = '1-800-649-4637';
			}
	
        let country = this.getCountry();
		if (brand === 'virgin' && country === 'au') {
			brand = 'virgin_au';
		} else if ((brand === 'laithwaiteswine' || brand === 'laithwaites') &&
					country === 'nz') {
			brand = 'laithwaites_nz';
		} else if (brand === 'laithwaites' && country === 'au') {
			brand = 'winepeople';
		} else if (brand === 'tcmwineclub' || brand === 'natgeowine' || brand === 'nprwineclub' || brand === 'bhgwine') {
			brand = 'laithwaites';
		}

		switch (brand) {
			case 'wsjwine': {
				data = {
					tag: 'wsj',
					name: 'WSJwine from The Wall Street Journal',
					nameShort: 'WSJwine',
					brandKey: 'WSJ',
					url: 'https://www.wsjwine.com',
					urlShort: 'wsjwine.com',
					unlimited: 'Advantage',
					unlimitedFull: 'WSJwine Advantage',
					unlimtedUrl: '/jsp/product/productDetails.jsp?productId=prod2170014',
					unlimitedBom: '00034SV',
					sunUnlimitedBom: '00034SV',
					address: 'Customer Service PO Box 2470',
					city: 'Largo',
					state: 'FL',
					zip: '33779',
					phone: '1-877-975-9463',
					advicer_phone: '1-888-863-7384',
                    advisor_mail: 'wineadvisors@wsjwine.com',
					email: 'customerservice@wsjwine.com',
					serviceEmail: 'service@wsjwine.com',
					logo: '/images/us/common/recr/wsj_logo_rebrand.png',
					locale: 'en_US_USWSJ',
					companyCode: 'US',
					caseImageUrl: '/images/us/en/product/',
					itemTrackingUrl: 'https://wsj.narvar.com/tracking/wsjwine/fedex?tracking_numbers='
				};
				break;
			}
			case 'laithwaites': {
				data = {
					tag: 'law',
					name: 'Laithwaites',
					nameShort: 'Laithwaites',
					brandKey: '4s',
					url: 'https://www.laithwaites.com',
					urlShort: 'laithwaites.com',
					unlimited: 'Unlimited',
					unlimitedFull: 'Laithwaites Unlimited',
					unlimtedUrl: '/jsp/product/productDetails.jsp?productId=prod2130001',
					unlimitedBom: '00033SV',
					sunUnlimitedBom: '00033SV',
					address: 'PO Box 5160',
					city: 'Largo',
					state: 'FL',
					zip: '33779',
					phone: phoneNumber,
					advicer_phone: '1-888-863-7386',
                    advisor_mail: 'wineadvisors@laithwaites.com',
					email: 'customerservice@laithwaites.com',
					serviceEmail: 'service@laithwaites.com',
					logo: '/images/us/en/brands/lw/recruitment/template6/header1/lw-logo.png',
					locale: 'en_US_US4S',
					companyCode: 'US',
					caseImageUrl: '/images/us/en/product/',
					itemTrackingUrl: 'https://laithwaiteswine.narvar.com/tracking/laithwaiteswine/fedex?tracking_numbers='
				};
				break;
			}
			case 'virgin': {
				data = {
					tag: 'vir',
					name: 'Virgin Wines US',
					nameShort: 'Virgin Wines',
					brandKey: 'Virgin',
					url: 'https://www.virginwines.com',
					urlShort: 'virginwines.com',
					unlimited: 'Unlimited',
					unlimitedFull: 'Virgin Wines Unlimited',
					unlimtedUrl: '/jsp/product/productDetails.jsp?productId=prod2170015',
					unlimitedBom: '00035SV',
					sunUnlimitedBom: '00035SV',
					address: 'PO Box 1980',
					city: 'Largo',
					state: 'FL',
					zip: '33779',
					phone: '1-866-426-0336',
					email: 'service@virginwines.com',
					serviceEmail: 'service@virginwines.com',
					logo: '/images/us/en/brands/virgin/virgin_logo.png',
					locale: 'en_US_USVirgin',
					companyCode: 'US',
					caseImageUrl: '/images/us/en/brands/virgin/product/',
					itemTrackingUrl: 'https://virgin.narvar.com/tracking/virginwines/fedex?tracking_numbers='
				};
				break;
			}
			case 'macyswinecellar': {
				data = {
					tag: 'mcy',
					name: 'Macys Wine Cellar US',
					nameShort: "Macy's Wine Cellar",
					brandKey: 'MACYS',
					url: 'https://www.macyswinecellar.com',
					urlShort: 'macyswinecellar.com',
					unlimited: 'Unlimited',
					unlimitedFull: "Macy's Wine Cellar Unlimited",
					unlimtedUrl: '/jsp/product/productDetails.jsp?productId=prod2170015',
					unlimitedBom: '00036SV',
					sunUnlimitedBom: '00036SV',
					address: 'PO Box 1830',
					city: 'Largo',
					state: 'FL',
					zip: '33779',
					phone: '1-888-997-0319',
					email: 'customerservice@macyswinecellar.com',
					serviceEmail: 'service@macyswinecellar.com',
					logo: '/images/us/mcy/common/mcy_logo.png',
					locale: 'en_US_USMACYS',
					companyCode: 'US',
					caseImageUrl: '/images/us/en/product/',
					itemTrackingUrl: 'https://macyswinecellar.narvar.com/tracking/macyswinecellar/fedex?tracking_numbers='
				};
				break;
			}
			case 'virgin_au': {
				data = {
					tag: 'vir',
					name: 'Virgin Wines',
					nameShort: 'Virgin Wines',
					brandKey: 'AUVIRGIN',
					url: 'https://www.virginwines.com.au',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: 'Attn: Customer Service',
					mailName: 'Virgin Wines Australia',
					poBox: 'P.O Box 665',
					city: 'Strawberry Hills',
					state: 'NSW',
					zip: '2012',
					phone: '1300 712 870',
					emailAddress: 'customerservice@virginwines.com.au',
					hours: {
						monToFri: '8am - 5pm AEST'
					}
				};
				break;
			}
			case 'theaustralianwine': {
				data = {
					tag: 'adc',
					name: 'The Australian Wine',
					brandKey: 'ADC',
					url: 'https://www.theaustralianwine.com.au',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: 'The Australian Wine',
					mailName: 'Member Service',
					poBox: 'PO Box 665',
					city: 'Strawberry Hills',
					state: 'NSW',
					zip: '2012',
					phone: '1300 765 021',
					emailAddress: 'info@theaustralianwine.com.au',
					hours: {
						monToFri: '8am - 5pm AEST'
					}
				};
				break;
			}
			case 'winepeople': {
				data = {
					tag: 'wpe',
					name: 'Laithwaites',
					brandKey: 'WP',
					url: 'https://www.winepeople.com.au',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: 'Laithwaites Customer Service',
					mailName: false,
					poBox: 'PO Box 665',
					city: 'Strawberry Hills',
					state: 'NSW',
					zip: '2012',
					phone: '1300 362 629',
					emailAddress: 'customerservice@laithwaites.com.au',
					hours: {
						monToFri: '8am - 5pm AEST'
					}
				};
				break;
			}
			case 'velocity': {
				data = {
					tag: 'vws',
					name: 'Virgin Wines Redemption store',
					brandKey: 'VWS',
					url: 'https://redemption.virginwines.com.au',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: 'Virgin Wines Redemption Customer Service',
					mailName: false,
					poBox: 'PO Box 665',
					city: 'Strawberry Hills',
					state: 'NSW',
					zip: '2012',
					phone: '1300 241 080',
					emailAddress: 'customerservice.redemption@virginwines.com.au',
					hours: {
						monToFri: '8am - 5pm AEST'
					}
				};
				break;
			}
			case 'laithwaites_nz': {
				data = {
					tag: 'law',
					name: "Laithwaites",
					brandKey: 'LW',
					url: 'https://www.laithwaiteswine.co.nz',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: "Laithwaites Customer Service",
					mailName: "Freepost No 246256, Laithwaite's Wine",
					poBox: 'PO Box 210105',
					city: 'Laurence Stevens Drive',
					state: 'Auckland',
					zip: '2154',
					phone: '0800 004 612',
					emailAddress: 'customerservice@laithwaites.co.nz',
					hours: {
						monToFri: '10am to 7pm (NZST)'
					}
				};
				break;
			}
			case 'directwines': {
				data = {
					tag: 'law',
					name: 'Direct Wines',
					brandKey: 'LAW',
					url: 'https://www.directwines.com.tw/',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: 'Direct Wines Customer Service',
					mailName: false,
					poBox: 'PO Box 665',
					city: 'Strawberry Hills',
					state: 'NSW',
					zip: '2012',
					phone: '(02) 7701-0188',
					emailAddress: 'customerservice@velocitywinestore.com.au',
					hours: {
						monToFri: '8am - 6pm AEST'
					}
				};
				break;
			}
			case 'directwinesHK': {
				data = {
					tag: 'hlw',
					name: 'Direct Wines',
					brandKey: 'HLW',
					url: 'https://www.directwines.com.hk/',
					itemTrackingUrl: 'https://www.fastway.com.au/tools/track?l=',
					address: 'Direct Wines Customer Service',
					mailName: false,
					poBox: 'Suite 1201, Wing On Centre, 111 Connaught Road Central',
					city: 'Sheung Wan',
					state: 'Hong Kong',
					zip: 'Hong Kong',
					phone: '8120 3826',
					emailAddress: 'customerservicehk@directwinesasia.com',
					hours: {
						monToFri: '10am – 8pm (HK time)'
					}
				};
				break;
			}
			case 'omahasteakswine': {
				data = {
					tag: 'osw',
					name: 'Omaha steaks wine',
					nameShort: 'Omaha',
					brandKey: 'OSW',
					url: 'https://www.omahasteakswine.com',
					urlShort: 'omahasteakswine.com',
					unlimited: 'Advantage',
					unlimitedFull: 'Omaha Steaks Wine Advantage',
					unlimitedBom: '00034SV',
					sunUnlimitedBom: '00034SV',
					phone: '1-877-975-9463',
					email: 'customerservice@wsjwine.com',
					serviceEmail: 'service@wsjwine.com',
					logo: '/images/us/osw/common/logos/OmahaSteaksWine.svg',
					favIcon: '/images/us/osw/common/icons/osw-favicon.svg',
					locale: 'en_US_USOSW',
					companyCode: 'DWI',
					caseImageUrl: '/images/us/en/product/',
					itemTrackingUrl:
						'https://wsj.narvar.com/tracking/wsjwine/fedex?tracking_numbers=',
					aboutUsLink: '/jsp/aboutus/us/common/aboutus.jsp',
					googleAnalytics: {
						merchantId: 'PNfuM9c76bu4-OsEM6hNwYQhye5__zWBPAycDkAy4sw',
						gtmId: 'GTM-MD2N2MGN',
					},
					POBox: '6821',
					footer: {
						header: 'The Omaha steaks wine Guarantee',
					},
					guarantee:
						'If ever a bottle fails to delight, you get your money back.',
				};
				break;
			}
			default:
				return data;
		}

		return data;
	}
};
export default brandUtil;
