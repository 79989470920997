import React, { useRef } from 'react';
import styled from '@emotion/styled';
import setEventTracking from '../../util/setEventTracking';
import TrendingProducts from '../atom/TrendingProducts';
import getColor from '../../util/getColor';
import { EmotionBreakpoint } from 'dw-us-ui';

const Wrapper = styled.div`
	&.menu-collapsed {
		display: none;
	}
	width: 100%;
	z-index: 1000;
	position: absolute;
	left: 0;
	background-color: white;
	${EmotionBreakpoint('tablet')} {
		width: 100vw;
		left: -15px;
	}
	.shop-dropdown {
		.wsj & {
			border-bottom: 1px solid #d2e1e1; /* $laithwaites-light-gray */
			padding: 30px 70px 0;
		}
		.osw & {
			border-bottom: 1px solid #ededed;
			padding: 40px 120px 0;
		}
		@media (max-width: 1400px) {
			padding: 30px 50px 0;
		}
		@media (max-width: 1200px) {
			padding: 30px 30px 0;
		}
		@media (max-width: 1050px) {
			padding: 30px 15px 0;
		}
	}
	.row a {
		text-decoration: none;
	}
	.centered {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.nav-img-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		height: 70%;
		background-color: #fff;
	}
	.drop-down-column {
		display: flex;
		text-transform: none;
		.wsj & {
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
		.osw & {
			border-bottom: 1px solid #ededed;
		}
	}
	.drop-down-row {
		color: black;
		flex-basis: ${({ columnCount }) => `calc(100%/${columnCount})`};
		.horizontal-line {
			width: 86%;
			.wsj & {
				border-bottom: 1px solid #cccccc;
				margin: 8px 0 5px;
			}
			.osw & {
				border-bottom: 1px solid #ededed;
				margin: 10px 0;
			}
		}
		.group {
			.wsj & {
				padding-bottom: 20px;
			}
			.osw & {
				padding-bottom: 42px;
			}
			text-align: left;
			.group-item-link {
				&:hover {
					color: black !important;
					text-decoration: none;
				}
			}
		}
	}
	.all-wines {
		margin: auto;
		text-transform: none;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		a {
			color: #000 !important;
			font-family: Arial, sans-serif;
		}
		a:hover {
			color: black !important;
			text-decoration: none;
			cursor: pointer;
		}
		.wsj & {
			font-family: Open Sans;
			padding: 15px 0;
		}
		.osw & {
			font-family: Arial;
			padding: 24px 0;
		}
	}
	.nav-item-container {
		display: flex;
		align-items: center;
		gap: 15px;
		padding: 5px 0;
		width: 86%;
	}
`;

const GroupHeading = styled.div`
	color: ${({ fontColor }) => fontColor};
	.wsj & {
		font-size: 14px;
	}
	.osw & {
		font-family: 'Neue-haas-grotesk', sans-serif;
		font-size: 18px;
		padding: 10px 0 0 0;
	}
	font-weight: 700;
	line-height: 24px;
	font-family: georgia, sans-serif;
`;

const GroupItem = styled.div`
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	cursor: pointer;
	.wsj & {
		font-size: 14px;
		color: #000 !important;
		font-family: Arial, sans-serif;
	}
	.osw & {
		font-family: ${({ theme }) => theme?.fonts?.primary};
		font-size: 16px;
		color: #222 !important;
	}
`;

const NavItemColor = styled.div`
	width: 15px;
	height: 15px;
	background: white;
	border-radius: 5px;
	border: 0.7px solid #747474;
	background: ${({ bgColor }) => bgColor};
`;

const NavigationDesktopDropdownMenu = ({
	setDropdownOpen,
	dropdownOpen,
	shopRef,
	onMouseEnter,
	onMouseLeave,
	isOpen,
	dropDownData,
	footerLink,
	menuHeading,
}) => {
	// Create a ref that we add to the element for which we want to detect outside clicks
	const wrapperRef = useRef();
	return (
		<Wrapper
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={` ${isOpen ? 'expand' : 'menu-collapsed'}`}
			// ref={wrapperRef}
			columnCount={dropDownData?.length}
		>
			<div className='text-dark bg-white position-absolute shop-dropdown w-100'>
				<div className='drop-down-column'>
					{dropDownData &&
						dropDownData?.map((column) => {
							return (
								<div className='drop-down-row'>
									{column &&
										column?.fields?.navItems?.map((row) => {
											return (
												<div className='group'>
													<GroupHeading
														fontColor={
															getColor(row?.fields?.background) || '#000'
														}
													>
														{row?.fields?.heading}
													</GroupHeading>
													<div className='horizontal-line' />
													<div>
														{row?.fields?.type !== 'trending_products' ? (
															row?.fields?.navItems?.map((groupItem) => {
																return (
																	<div className='nav-item-container'>
																		{row?.fields?.heading === 'Wine Styles' && (
																			<div>
																				<NavItemColor
																					bgColor={getColor(
																						groupItem?.fields?.color
																					)}
																				/>
																			</div>
																		)}
																		<a
																			href={groupItem?.fields?.url}
																			onClick={() => {
																				setEventTracking(
																					menuHeading,
																					row?.fields?.heading,
																					'Click',
																					`${groupItem?.fields?.text}`
																				);
																			}}
																			className='group-item-link'
																		>
																			<GroupItem>
																				{groupItem?.fields?.text}
																			</GroupItem>
																		</a>
																	</div>
																);
															})
														) : (
															<TrendingProducts
																productsDetails={row?.fields?.navItems}
																menuHeading={menuHeading}
																subHeading={row?.fields?.heading}
															/>
														)}
													</div>
												</div>
											);
										})}
								</div>
							);
						})}
				</div>

				<div
					onClick={() => {
						setEventTracking(
							menuHeading,
							null,
							'Click',
							footerLink?.fields?.text
						);
					}}
					className='all-wines'
				>
					<a href={footerLink?.fields?.url}>{footerLink?.fields?.text}</a>
				</div>
			</div>
		</Wrapper>
	);
};

export default NavigationDesktopDropdownMenu;
