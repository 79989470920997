import React from 'react';
import styled from '@emotion/styled';
import getColor from '../../util/getColor';
import setEventTracking from '../../util/setEventTracking';

const StyledNavAnchor = styled.a`
	text-align: left !important;
	font-size: 14px !important;
	.wsj & {
		font-family: Arial, sans-serif;
		color: #888 !important;
		color: ${({ fontColor }) => `${fontColor}!important`};
	}
	.osw & {
		font-family: ${({ theme }) => theme?.fonts?.primary};
		color: #890419;
	}
	font-weight: 700 !important;
	text-transform: uppercase;
	line-height: 26px !important;
	letter-spacing: 1.8px;
`;
const StyledNavItems = styled.div`
	.dropdown {
		display: flex;
		align-items: center;
		height: 100%;
		.wsj & {
			border-bottom: 3px solid #ffffff;
		}
		.osw & {
			border-bottom: 3px solid #ededed;
		}
		&:hover {
			.wsj & {
				border-bottom: 3px solid #e92440 !important;
			}
			.osw & {
				border-bottom: 3px solid #890419 !important;
			}
		}
	}
`;

const NavigationItem = (props) => {
	const fontColor = getColor(props?.fields?.background);

	return (
		<StyledNavItems>
			<li class='dropdown'>
				<StyledNavAnchor
					href={props?.url}
					fontColor={fontColor || '#888888'}
					onClick={(e) => {
						e.preventDefault();
						setEventTracking(props?.fields?.heading, null, 'Click', null);
						location.href = props?.url;
					}}
				>
					{props?.fields?.heading}
				</StyledNavAnchor>
			</li>
		</StyledNavItems>
	);
};
export default NavigationItem;
