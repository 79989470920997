import React from 'react';
import styled from '@emotion/styled';
import Navbar from './navbar/organisms/Navbar';

const MainContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
const App = () => {
	return (
		<MainContainer>
			<Navbar />
		</MainContainer>
	);
};

export default App;
