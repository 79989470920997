import styled from '@emotion/styled';
import React, { useEffect, useState, useContext } from 'react';
import { wineryDirectStates, EmotionBreakpoint } from 'dw-us-ui';
import NavbarItemShop from './NavbarItemShop';
import NavbarItemMembership from './NavbarItemMembership';
import NavigationItem from './NavigationItem';

const StyledNavUl = styled.ul`
	max-height: 62px;
	.nav-menu-item{
		border-bottom: 3px solid #00000000;
		&:hover {
		border-bottom: 3px solid #CF004F;
		padding-right: 0px!important;
		margin-right: 1rem!important;
		}
		${EmotionBreakpoint('tablet')} {
			&:hover {
				border-bottom: 3px solid #00000000;
			}
		}
	}
	.nav-menu-drop-down{
		border-bottom: 3px solid #e92440!important;
		.osw & {
			border-bottom: 3px solid #890419!important;
		}
	}
	.nav-items{
		.wsj & {border-bottom: 5px solid #00000000;}
	}
	.nav-click-item{
		padding-top: 1.2rem!important;
		padding-bottom: 0.8rem!important;
	}
	}
	@media (max-width: 991px) and (min-width: 768px) {
		width: 100vw;
	}
	@media (max-width: 991px) {
		justify-content: space-around!important;
	}
`;

const StyledNavContainer = styled.div`
	max-height: 62px;
`;

const NavbarItems = ({
	toggleDropdownOpen,
	currentPage,
	shopRef,
	onGiftMouseEnter,
	onGiftMouseLeave,
	toggleMembership,
	onMemberMouseEnter,
	onMemberMouseLeave,
	navItems,
}) => {
	const [handleTabletDropDown, setHandleTabletDropDown] = useState([]);

	const getNavIem = (items) => {
		const { fields } = items;
		const { heading } = fields;
		const type = fields?.type;
		if (type) {
			let isValueUpdated = handleTabletDropDown.some((x) => {
				if (x?.title === heading) {
					return true;
				}
			});
			if (!isValueUpdated) {
				setHandleTabletDropDown((prevState) => {
					let temp = [...prevState, { title: heading, show: false }];
					return temp;
				});
			}
		}
		switch (type) {
			case 'template_1':
				let showDropDownShop;
				handleTabletDropDown?.forEach((ele) => {
					if (ele?.title === heading) {
						showDropDownShop = ele?.show;
					}
				});
				return (
					<NavbarItemShop
						dropDownData={fields}
						showDropDown={showDropDownShop}
						setHandleTabletDropDown={setHandleTabletDropDown}
					/>
				);

			case 'template_2':
				let showDropDownMembership;
				handleTabletDropDown?.forEach((ele) => {
					if (ele?.title === heading) {
						showDropDownMembership = ele?.show;
					}
				});
				return (
					<NavbarItemMembership
						dropDownData={fields}
						showDropDown={showDropDownMembership}
						setHandleTabletDropDown={setHandleTabletDropDown}
					/>
				);

			default: {
				return (
					<NavigationItem
						className='ml-2'
						heading={fields?.heading}
						url={fields?.link?.fields?.url}
						fields={fields}
					/>
				);
			}
		}
	};

	let desktopDisplay = (
		<StyledNavUl className='navbar-nav d-flex flex-row  px-3 h-100'>
			{navItems &&
				navItems.length > 0 &&
				navItems.map((item, index) => {
					return getNavIem(item);
				})}
		</StyledNavUl>
	);

	return <>{desktopDisplay}</>;
};

export default NavbarItems;
