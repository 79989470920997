import styled from '@emotion/styled';
import { wineryDirectStates } from 'dw-us-ui';
import React, { useEffect, useState } from 'react';
import NavbarDesktop from './NavbarDesktop';
import {
	useContentfulEntries,
	useContentfulPreview,
} from 'dw-us-ui/src/bundles/laithwaites-bundle';
import { DesktopDisplay, NonDesktopDisplay } from '../../util/Breakpoint';
import NavbarMobile from './NavbarMobile';
import brandUtil from '../../../../../../js_source/com/utils/brand';

const Navbar = () => {
	const [content, setContent] = useState();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const { isPreview } = useContentfulPreview('navigationConfig_preview');
	let additionalFilters = {
		'fields.brand': brandUtil.content(brandUtil.getBrand()).brandKey,
		'fields.slug': 'navigation-menu',
		'fields.isWd': wineryDirectStates.includes(pageLayer[0].stateCode),
	};

	let contentReturn = useContentfulEntries({
		contentType: 'navigationConfig',
		environment: 'master',
		contentfulProperties: {
			isProduction: !isPreview,
		},
		additionalFilters,
	});

	useEffect(() => {
		if (contentReturn) {
			setContent(contentReturn);
		}
	}, [contentReturn]);

	const toggleDropdownOpen = () => {
		setDropdownOpen(!dropdownOpen);
		if (!dropdownOpen) {
			document.querySelector('.navbar-app-overlay').style = 'display: block';
		} else {
			document.querySelector('.navbar-app-overlay').style = 'display: none';
		}
	};

	return (
		<>
			<DesktopDisplay>
				<NavbarDesktop navItems={content?.navigations} />
			</DesktopDisplay>
			<NonDesktopDisplay>
				<NavbarMobile
					dropdownOpen={dropdownOpen}
					toggleDropdownOpen={toggleDropdownOpen}
					setDropdownOpen={setDropdownOpen}
					navItems={content?.navigations}
				/>
			</NonDesktopDisplay>
		</>
	);
};

export default Navbar;
