import React, { useCallback, useEffect, useContext, useState } from 'react';
import { PageLayerContext } from '../../context/PageLayerContext';
import styled from '@emotion/styled';
import axios from 'axios';
import {
	Button,
	Collapse,
	CardHeader,
	CardBody,
	Card,
	Col,
	Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import setEventTracking from '../../util/setEventTracking';
import NavbarAccordionMenu from './NavbarAccordionMenu';
import CMSMobileMembershipMenu from './CMSMobileMembershipMenu';
import getColor from '../../util/getColor';
import brandUtil from '../../../../../../js_source/com/utils/brand';

const NavbarBurgerAccordion = ({
	setDropdownOpen,
	dropdownOpen,
	toggleAccountMenu = () => {},
	shopRef,
	giftRef,
	navItems,
}) => {
	const [collapseState, setCollapseState] = useState([]);
	const [isMyAccountOpen, setisMyAccountOpen] = useState(false);

	const pageLayerData = useContext(PageLayerContext);
	const [renderUnlimited, setRenderUnlimited] = useState();
	const visitorTypeDetailed = pageLayer[0].visitorTypeDetailed;
	const triggerGAEvent = useCallback((e, label, link) => {
		e.preventDefault();
		setEventTracking('Main_Navigation', 'Shop_Click', label);
		if (link) {
			location.href = link;
		}
	}, []);
	const brandTag = brandUtil
		.content(brandUtil.getBrand())
		.brandKey?.toLowerCase();
	useEffect(() => {
		if (pageLayerData.ou === '0' || pageLayerData.ou === '4') {
			setRenderUnlimited(false);
		} else {
			setRenderUnlimited(true);
		}
	}, [pageLayerData]);

	const softLogout = () => {
		axios
			.get('/jsp/templates/page_elements/us/common/soft_logout_link.jsp')
			.then((res) => {
				const data = res.data.split('"')[1];
				window.location.href = data;
			})
			.catch((err) => {
				if (err.response) {
					console.log(err);
				}
			});
	};

	const getNavIem = (item) => {
		const { fields } = item;
		const type = fields?.type;
		switch (type) {
			case 'template_1':
				return (
					<NavbarAccordionMenu
						setDropdownOpen={setDropdownOpen}
						dropdownOpen={dropdownOpen}
						shopRef={shopRef}
						toggleAccountMenu={toggleAccountMenu}
						navItems={fields?.navItems}
						footerLink={fields?.link}
						mainHeading={fields?.heading}
					/>
				);

			case 'template_2':
				return (
					<CMSMobileMembershipMenu
						setDropdownOpen={setDropdownOpen}
						dropdownOpen={dropdownOpen}
						content={fields}
						mainHeading={fields?.heading}
					/>
				);
			default:
				return null;
		}
	};

	const openIcon = () => (
		<img
			src='/assets/icons/us/law/arrows/icon-plus-black.svg'
			alt='plus icon'
			width='16px'
			height='16px'
		/>
	);
	const closedIcon = () => (
		<img
			src='/images/us/common/icons/law_rebrand/icon-minus-black.svg'
			alt='minus icon'
			width='16px'
			height='16px'
		/>
	);

	const display = navItems?.map((item, idx) => {
		return item?.fields?.type ? (
			<Card
				key={idx}
				className='rounded-0 m-n1'
				style={{ borderBottom: '1px solid #CCCCCC' }}
			>
				<Button
					onClick={() =>
						setCollapseState((prevState) => ({
							...prevState,
							[idx]: !prevState[idx],
						}))
					}
					className='text-dark button-style p-0 bg-secondary'
					style={{ boxShadow: brandTag === 'osw' && 'none' }}
				>
					<CardHeader
						className='d-flex flex-row justify-content-between align-items-center card-header bg-secondary'
						style={{
							borderBottom: '1px solid rgba(0, 0, 0, 0)',
							borderRadius: '0px',
						}}
						id={idx}
					>
						<h6 className='mb-0'>
							<span className='nav-font accordion-menu-item text-dark p-0'>
								{item?.fields?.heading}
							</span>
						</h6>

						{collapseState[idx] ? closedIcon() : openIcon()}
					</CardHeader>
				</Button>

				<Collapse className='text-dark' isOpen={collapseState[idx]}>
					<Card className='rounded-0'>
						<Row>
							<Col>{getNavIem(item)}</Col>
						</Row>
					</Card>
				</Collapse>
			</Card>
		) : pageLayerData.brandDomain === 'tcm' ? null : (
			<Card
				key={idx}
				className='rounded-0 m-n1 sub-nav-item'
				style={{ borderBottom: '1px solid  rgba(0, 0, 0, 0.125)' }}
			>
				<a
					href={item?.fields?.link?.fields?.url}
					onClick={() => {
						setEventTracking(item?.fields?.heading, null, 'Click', null);
					}}
				>
					<Button
						className='text-dark button-style p-0'
						style={{ boxShadow: brandTag === 'osw' && 'none' }}
					>
						<CardHeader
							className='d-flex flex-row justify-content-between align-items-center input card-header browse-wine-header'
							style={{
								borderBottom: '1px solid rgba(0, 0, 0, 0)',
							}}
						>
							<ButtonLink
								fontColor={getColor(item?.fields?.background) || '#000'}
							>
								{item?.fields?.heading}
							</ButtonLink>
						</CardHeader>
					</Button>
				</a>
			</Card>
		);
	});

	return (
		<Wrapper>
			<Collapse className='w-100 text-dark' isOpen={navItems}>
				<div className='accordion w-100'>{display}</div>
			</Collapse>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	.row a {
		text-decoration: none;
	}
	.accordion {
		overflow-x: hidden;
		.osw & {
			background-color: #fff;
		}
	}
	.browse-wine-header {
		padding-right: 1rem !important;
	}
	.button-style {
		width: 100%;
		background-color: white;
		padding: 4px 24px 4px 24px;
		.osw & {
			border-radius: 0px !important;
		}
	}
	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.wsj & {
			padding-top: 12px;
			padding-bottom: 12px;
		}
		.osw & {
			padding: 8px 0;
		}
	}
	.accordion-menu-item {
		text-transform: uppercase;
		letter-spacing: 1.8px;
		font-weight: 700;
		.wsj & {
			font-size: 15px;
			font-family: Arial, sans-serif;
		}
		.osw & {
			font-size: 12px;
			font-family: ${({ theme }) => theme?.fonts?.primary};
		}
	}
	.bg-sandrift {
		background-color: #e92440;
	}
	.bg-secondary {
		background-color: #ffffff;
		.osw & {
			border-radius: 0 !important;
		}
	}
	.text-white {
		color: #ffffff;
	}
	.text-dark {
		color: #000000;
	}
	.my-account-padding-left {
		padding-left: 40px;
	}
`;

const ButtonLink = styled.div`
	.wsj & {
		font-size: 15px;
		font-family: Arial, sans-serif;
	}
	.osw & {
		font-size: 12px;
		font-family: ${({ theme }) => theme?.fonts?.primary};
	}
	color: ${({ fontColor }) => `${fontColor}!important`};
	font-weight: 700;
	letter-spacing: 1.8px;
`;
const CardMainContainer = styled.div`
	.card {
		border: ${({ isCardOpened }) =>
			isCardOpened ? '1px solid red!important;' : '1px solid black!important;'};
	}
	.card-body {
		borderradius: 0px;
		padding: 0 !important;
	}
	.nav-item-padding {
		background-color: #fff;
	}
	.nav-item-container {
		display: flex;
		align-items: center;
		padding: 6px 22px 12px 17px;
		.wsj & {
			border-bottom: 1px solid #fff;
		}
		.osw & {
			border-bottom: 1px solid #ededed;
		}

		.nav-item-style {
			font-weight: 400;
			font-size: 14px;
			text-transform: capitalize;
			font-family: Arial, sans-serif;
			color: rgb(0, 0, 0) !important;
			text-align: left;
		}
	}
`;
NavbarBurgerAccordion.propTypes = {
	dropdownOpen: PropTypes.bool,
	toggleNavDropdown: PropTypes.func,
};
NavbarBurgerAccordion.defaultProps = {};
export default NavbarBurgerAccordion;
