import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'reactstrap';
import NavbarItems from '../molecules/NavbarItems';
import NavbarBurgerAccordion from '../molecules/NavbarBurgerAccordian';
import { TabletDisplay, MobileDisplay } from '../../util/Breakpoint';
import useSubmenuOpen from '../../hooks/useSubmenuOpen';
import NavbarAccordionMenu from '../molecules/NavbarAccordionMenu';
import CMSDesktopMenuDropdown from '../molecules/CMSDesktopMenuDropdown';

const NavbarContainer = styled.nav`
	top: 0;
`;

const MobileNavContainer = styled.div`
	width: 100%;
`;

const NavbarLogoMain = styled.a`
	img {
		max-width: 100%;
		width: 130px;
		}
	};
`;

const NavbarMobile = ({
	setDropdownOpen,
	dropdownOpen,
	toggleDropdownOpen,
	currentPage,
	isAccountPage,
	userName,
	promoBadge,
	navItems,
}) => {
	const [accountMenuOpen, setAccountMenuOpen] = useState(false);
	const [position, setPosition] = useState(true);
	const [
		membershipOpen,
		setMembershipOpen,
		handleMembershipEnter,
		handleMembershipLeave,
	] = useSubmenuOpen(false);

	const toggleAccountMenu = (toggle) => {
		setAccountMenuOpen(toggle === undefined ? !accountMenuOpen : toggle);
		if (accountMenuOpen || toggle === false) {
			setTimeout(() => {
				document.querySelector('.navbar-app-overlay').style.display = 'none';
			}, 100);
		} else {
			setTimeout(() => {
				document.querySelector('.navbar-app-overlay').style.display = 'block';
			}, 100);
		}
	};

	const togglePosition = () => {
		setPosition(window.scrollY > 10 ? false : true);
	};

	useEffect(() => {
		var bodyContainer = document.body;
		var distanceFromTop = bodyContainer.getBoundingClientRect().top;

		// If the page loads anywhere besides the very top of the page make sure that the nav is not transparent
		if (distanceFromTop !== 0) {
			setPosition(false);
		}

		window.addEventListener('scroll', togglePosition);

		return () => {
			if (window) {
				window.removeEventListener('scroll', togglePosition);
			}
		};
	}, []);

	const shopRef = useRef();
	const giftRef = useRef();
	const burgerRef = useRef();
	const profileRef = useRef();

	let testViewWidth = window.innerWidth;

	return (
		<MobileNavContainer>
			<NavbarContainer
				className={
					'navbar bg-white text-dark w-100 flex-column p-0 mb-0 ' +
					(position ? '' : 'position-fixed')
				}
			>
				<TabletDisplay>
					<NavbarItems
						setDropdownOpen={setDropdownOpen}
						dropdownOpen={dropdownOpen}
						toggleDropdownOpen={toggleDropdownOpen}
						currentPage={currentPage}
						shopRef={shopRef}
						toggleMembership={() => {
							setMembershipOpen((v) => !v);
						}}
						// onMemberMouseEnter={handleMembershipEnter}
						onMemberMouseLeave={handleMembershipLeave}
						navItems={navItems}
					/>
					<NavbarAccordionMenu
						setDropdownOpen={setDropdownOpen}
						dropdownOpen={dropdownOpen}
						toggleAccountMenu={toggleAccountMenu}
						shopRef={shopRef}
						profileRef={profileRef}
					/>
					<CMSDesktopMenuDropdown
						isOpen={membershipOpen}
						onMouseEnter={handleMembershipEnter}
						onMouseLeave={handleMembershipLeave}
					/>
				</TabletDisplay>

				<MobileDisplay>
					<NavbarBurgerAccordion
						setDropdownOpen={setDropdownOpen}
						dropdownOpen={dropdownOpen}
						toggleAccountMenu={toggleAccountMenu}
						burgerRef={burgerRef}
						giftRef={giftRef}
						navItems={navItems}
					/>
				</MobileDisplay>
			</NavbarContainer>
		</MobileNavContainer>
	);
};

export default NavbarMobile;
