import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Col, Collapse } from 'reactstrap';
import getColor from '../../util/getColor';
import setEventTracking from '../../util/setEventTracking';

const CMSMobileMembershipMenu = ({
	setDropdownOpen,
	dropdownOpen,
	content,
	mainHeading,
}) => {
	// Create a ref that we add to the element for which we want to detect outside clicks
	const wrapperRef = useRef();

	if (content) {
		return (
			<Wrapper ref={wrapperRef}>
				<Collapse className='w-100 text-dark' isOpen={content}>
					<div className='m-0 mx-4 mt-3 content-style'>
						{content.description}
					</div>
					<div className='membership-content-container'>
						{content.navItems.map(({ fields }, index) => {
							return (
								<div key={index}>
									<GroupHeading
										fontColor={getColor(fields?.background) || '#000'}
									>
										{fields.heading}
									</GroupHeading>
									<div>
										{fields.navItems.map(({ fields: node }, i) => {
											return (
												<a
													key={i}
													className={`mb-2 align-self-stretch align-content-stretch d-flex`}
													href={node?.link?.fields?.url}
													onClick={() => {
														setEventTracking(
															mainHeading,
															fields.heading,
															'click',
															node.heading
														);
													}}
												>
													<Col
														xs='7'
														sm='6'
														className='p-0'
														style={{
															backgroundColor: getColor(node.background),
															display: 'flex',
															alignItems: 'center',
															paddingLeft: '17px',
															paddingRight: '17px',
														}}
													>
														<div className='m-4'>
															<h3
																className='m-0 mb-2 mobile-title'
																style={{
																	color: '#fff',
																	fontSize: '18px',
																	textTransform: 'none',
																	fontFamily: 'Arial,sans-serif',
																	marginBottom: '8px',
																	lineHeight: '21px',
																}}
															>
																{node.heading}
															</h3>
															<p
																className='mobile-description'
																style={{
																	color: '#fff',
																	fontSize: '13px',
																	lineHeight: '17px',
																}}
															>
																{node.description}
															</p>
														</div>
													</Col>
													<Col
														xs='5'
														sm='6'
														className='p-0 d-flex img-container'
													>
														<img
															className='img-fluid'
															src={
																node?.media?.fields?.mobileImagePath ||
																node?.media?.fields?.image?.fields?.file?.url ||
																node?.media?.fields?.imagePath
															}
														/>
													</Col>
												</a>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</Collapse>
			</Wrapper>
		);
	} else {
		return null;
	}
};

const Wrapper = styled.div`
	width: 100%;
	text-align: left;
	.row a {
		text-decoration: none;
	}
	.img-fluid {
		object-fit: none;
	}
	.osw & {
		background-color: #fff;
	}
	.content-style {
		font-size: 14px;
		line-height: 1.4em;
		text-transform: none;
		margin: 0 2.6rem 0;
		.wsj & {
			font-family: Georgia, sans-serif;
		}
		.osw & {
			padding: 24px 0 8px 0;
			font-family: ${({ theme }) => theme?.fonts?.primary};
		}
	}
	.membership-content-container {
		margin: 0 2.6rem 20px;
	}
	.align-content-stretch {
		display: flex;
		.wsj & {
			margin-bottom: 0.5rem;
		}
		.img-container {
			padding: 0;
		}
	}
	.mobile-title {
		.osw & {
			color: #222 !important;
			font-family: ${({ theme }) => theme?.fonts?.subHeader} !important;
			font-size: 16px !important;
			font-weight: 700 !important;
			line-height: 125% !important;
			letter-spacing: 0.16px !important;
		}
	}
	.mobile-description {
		.osw & {
			color: #222 !important;
			font-family: ${({ theme }) => theme?.fonts?.primary} !important !important;
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: 130%;
		}
	}
`;
const GroupHeading = styled.h5`
	text-transform: none;
	.wsj & {
		color: ${({ fontColor }) => fontColor};
		padding: 16px 0px;
		font-size: 14px;
		font-weight: 400;
		font-family: Georgia, sans-serif;
	}
	.osw & {
		padding: 10px 0px;
		color: #222;
		font-size: 16px;
		font-family: ${({ theme }) => theme?.fonts?.primary};
		font-weight: 700;
		border-bottom: 1px solid #d1d2d4;
		margin-bottom: 16px;
	}
`;
CMSMobileMembershipMenu.propTypes = {
	dropdownOpen: PropTypes.bool,
	toggleNavDropdown: PropTypes.func,
};

CMSMobileMembershipMenu.defaultProps = {};

export default CMSMobileMembershipMenu;
