import styled from '@emotion/styled';
import { EmotionBreakpoint } from 'dw-us-ui';
import React, { useCallback, useRef, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	Row,
} from 'reactstrap';
import getColor from '../../util/getColor';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import setEventTracking from '../../util/setEventTracking';
import brandUtil from '../../../../../../js_source/com/utils/brand';

const NavbarAccordionMenu = ({
	setDropdownOpen,
	dropdownOpen,
	toggleAccountMenu,
	shopRef,
	profileRef,
	navItems,
	footerLink,
	mainHeading,
}) => {
	const [collapseState, setCollapseState] = useState([]);
	const brandTag = brandUtil
		.content(brandUtil.getBrand())
		.brandKey?.toLowerCase();
	const triggerGAEvent = useCallback(
		(e, label, link, subHeading, mainHeading) => {
			e.preventDefault();
			setEventTracking(mainHeading, subHeading, 'Click', label);
			if (link) {
				location.href = link;
			}
		},
		[]
	);
	let displayDropDown = window.innerWidth < 768 ? true : dropdownOpen;
	let setPadding = window.innerWidth < 768 ? true : false;

	// Create a ref that we add to the element for which we want to detect outside clicks
	const wrapperRef = useRef();
	const hideMenuBackdrop = () => {
		document.querySelector('.navbar-app-overlay').style = 'display: none';
	};

	// Call hook passing in the ref and a function to call on outside click
	useOnClickOutside(
		wrapperRef,
		() => {
			if (window.innerWidth > 450) {
				setDropdownOpen(false);
				// hideMenuBackdrop();
			}
		}
		// shopRef,
		// profileRef
	);

	const openIcon = () => (
		<img
			src='/images/us/common/icons/law_rebrand/icon-minus-black.svg'
			alt='minus icon'
			width='16px'
			height='16px'
		/>
	);
	const closedIcon = () => (
		<img
			src='/assets/icons/us/law/arrows/icon-plus-black.svg'
			alt='plus icon'
			width='16px'
			height='16px'
		/>
	);

	let dropDownNavItems = [];
	let rowData = [];
	navItems?.forEach((item) => {
		item?.fields?.navItems?.forEach((subItem, index) => {
			if (subItem?.fields?.type !== 'trending_products') {
				if (index == 0) {
					dropDownNavItems.push(subItem.fields);
				} else {
					rowData.push(subItem.fields);
				}
			}
		});
	});
	dropDownNavItems = [...dropDownNavItems, ...rowData];

	const display = dropDownNavItems?.map((item, idx) => {
		return (
			<Card key={idx} className='rounded-0 m-n1 border-bottom'>
				<Button
					onClick={() =>
						setCollapseState((prevState) => ({
							...prevState,
							[idx]: !prevState[idx],
						}))
					}
					id='drop-down-card-header'
					className='text-dark p-0 item-button-style'
					style={{ boxShadow: brandTag === 'osw' && 'none' }}
				>
					<CardHeader
						className={
							'rounded-0 sub-nav-item d-flex flex-row justify-content-between align-items-center pl-5 ' +
							(idx === 0 && setPadding ? 'set-padding' : '')
						}
						id={idx}
					>
						<h6 className='mb-0'>
							<span className='header-style text-dark p-0'>
								{item?.heading}
							</span>
						</h6>

						{collapseState[idx] ? openIcon() : closedIcon()}
					</CardHeader>
				</Button>
				<CardContainer isCardOpened={true}>
					<Collapse
						className={
							'text-dark ' +
							(collapseState[idx] ? 'display-block' : 'display-none')
						}
					>
						<CardBody>
							<Row>
								<Col className='bg-secondary nav-item-padding pt-2 pb-3'>
									{item?.navItems.map((navItem) => {
										return (
											<a
												href={navItem?.fields?.url}
												onClick={(e) => {
													triggerGAEvent(
														e,
														navItem?.fields?.text,
														navItem?.fields?.url,
														item?.heading,
														mainHeading
													);
												}}
											>
												<div className='nav-item-container'>
													{item?.heading === 'Wine Styles' && (
														<NavItemColor
															bgColor={getColor(navItem?.fields?.color)}
														></NavItemColor>
													)}

													<div className='nav-item-style'>
														{navItem?.fields?.text}
													</div>
												</div>
											</a>
										);
									})}
								</Col>
							</Row>
						</CardBody>
					</Collapse>
				</CardContainer>
			</Card>
		);
	});

	return (
		<Wrapper ref={wrapperRef}>
			<Collapse
				className={
					'w-100 text-dark collapse-item ' +
					(displayDropDown ? 'display-block' : 'display-none')
				}
			>
				<div className='accordion w-100'>{display}</div>
				{dropDownNavItems?.length > 0 && (
					<Card className='rounded-0 m-n1 sub-nav-item Brower-wine-card'>
						<a
							href={footerLink?.fields?.url}
							onClick={(e) => {
								triggerGAEvent(
									e,
									footerLink?.fields?.text,
									footerLink?.fields?.url,
									null,
									mainHeading
								);
							}}
						>
							<Button className='text-dark brower-wine-btn p-0'>
								<CardHeader className='d-flex flex-row justify-content-between align-items-center input brower-wine'>
									<span className='brower-all-style'>
										{footerLink?.fields?.text}
									</span>
								</CardHeader>
							</Button>
						</a>
					</Card>
				)}
			</Collapse>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	.row a {
		text-decoration: none;
	}
	${EmotionBreakpoint('tablet')} {
		z-index: 1000;
		position: absolute;
		left: 0;
		.accordion {
			padding-bottom: 0px !important;
		}
	}
	.accordion {
		max-height: 300vh;
		overflow-x: hidden;
		overflow-y: hidden;
		padding: 0 23px 0 27px;
		.sub-nav-item {
			.wsj & {
				padding: 12px 0 12px 0;
			}
			.osw & {
				padding: 6px 0;
			}
		}
		.set-padding {
			padding-top: 4px;
		}
		.nav-item-padding {
			background-color: #fff;
		}
		.nav-item-container {
			display: flex;
			align-items: center;
			padding: 6px 22px 12px 17px;
			border-bottom: 1px solid #fff;

			.nav-item-style {
				font-weight: 400;
				font-size: 14px;
				text-transform: capitalize;
				font-family: Arial, sans-serif;
				color: rgb(0, 0, 0) !important;
				text-align: left;
				.osw & {
					font-family: 'Roboto';
				}
			}
		}
	}
	.brower-wine {
		text-transform: capitalize;
		padding-top: 15px !important;
		padding-left: 0.8rem !important;
		padding-right: 0.5rem !important;
		padding: 12px 22px 12px 28px !important;
		.brower-all-style {
			color: #000000;
			text-transform: initial;
			font-weight: 400;
			font-size: 16px;
			.wsj & {
				font-family: Georgia, sans-serif;
			}
			.osw & {
				letter-spacing: normal;
				font-family: 'Roboto';
			}
		}
	}
	.collapse-item {
		padding: 0 15px;
		background-color: #fff;
	}
	.item-button-style {
		background-color: #fff;
		width: 100%;
		padding: 0;
	}
	.header-style {
		text-transform: initial;
		font-weight: 400;
		.wsj & {
			font-size: 16px;
			font-family: Georgia, sans-serif;
		}
		.osw & {
			font-size: 14px;
			font-size: 16px;
			font-family: 'Roboto';
			@media (max-width: 576px) {
				letter-spacing: normal;
			}
		}
	}
	.display-block {
		display: block !important;
	}
	.display-none {
		display: none !important;
	}
	.brower-wine-btn {
		background-color: #fff;
		width: 100%;
		padding: 0;
	}
	.card-header {
		display: flex;
		justify-content: space-between;
		padding-top: 12px;
		padding-bottom: 12px;
		align-items: center;
	}
	.text-dark {
		color: #000000;
	}
	.wsj & .border-bottom {
		border-bottom: 1px solid #cccccc;
	}
`;

const CardContainer = styled.div`
	.card {
		border: ${({ isCardOpened }) =>
			isCardOpened ? '1px solid red!important;' : '1px solid black!important;'};
		.osw & {
			background-color: #fff;
		}
	}
	.card-body {
		borderradius: 0px;
		padding: 0 !important;
	}
`;
const NavItemColor = styled.div`
	width: 15px;
	height: 15px;
	background: white;
	border-radius: 5px;
	border: 0.7px solid #747474;
	background: ${({ bgColor }) => bgColor};
	margin-right: 20px;
`;

export default NavbarAccordionMenu;
