import styled from '@emotion/styled';
import React from 'react';

const StyledLoadingBlock = styled.div`
	background: linear-gradient(90deg, #ffffff, #d9d9d9, #ffffff);
	background-size: 600% 600%;
	height: ${({ height }) => height};
	width: ${({ width }) => width || '100vw'};
	min-width: ${({ isCarousel }) => (isCarousel ? '100vw' : 'inherit')};
	@media (min-width: 1400px) {
		height: ${({ isCarousel, wideHeight, height }) =>
			isCarousel ? '70vh' : wideHeight !== 'inherit' ? wideHeight : height};
	}
	-webkit-animation: LawGradient 2s ease infinite;
	-moz-animation: LawGradient 2s ease infinite;
	animation: LawGradient 2s ease infinite;

	@-webkit-keyframes LawGradient {
		0% {
			background-position: 0% 48%;
		}
		50% {
			background-position: 100% 53%;
		}
		100% {
			background-position: 0% 48%;
		}
	}
	@-moz-keyframes LawGradient {
		0% {
			background-position: 0% 48%;
		}
		50% {
			background-position: 100% 53%;
		}
		100% {
			background-position: 0% 48%;
		}
	}
	@keyframes LawGradient {
		0% {
			background-position: 0% 48%;
		}
		50% {
			background-position: 100% 53%;
		}
		100% {
			background-position: 0% 48%;
		}
	}
`;

const ImagePlaceholder = ({
	height = '100%',
	width = '100%',
	isCarousel = false,
	wideHeight = 'inherit',
	children = [],
	className,
}) => {
	return (
		<StyledLoadingBlock
			height={height}
			width={width}
			isCarousel={isCarousel}
			wideHeight={wideHeight}
			className={className}
		>
			{children}
		</StyledLoadingBlock>
	);
};

export default ImagePlaceholder;
