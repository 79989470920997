const COLOR_CONSTANT = {
	deepblue: '#06183A',
	'WSJ Blue 1': '#93BACD',
	'WSJ Blue 2': '#2C6F8A',
	'WSJ Link Blue': '#0274A9',
	'WSJ Cream 1': '#FAE1C8',
	'WSJ Cream 2': '#F7AB89',
	'WSJ Green 1': '#C9E7E1',
	'WSJ Green 2': '#3E837C',
	'WSJ Violet 1': '#EEE1F8',
	'WSJ Violet 2': '#A0ABCC',
	'WSJ Grey 1': '#F1F1F1',
	'WSJ Grey 2': '#848484',
	'WSJ Black': '#2B2A2F',
	'WSJ Red': '#E92440',
	'WSJ Rose': '#FCC4C4',
	'LAW Blue 1': '#055FAF',
	'LAW Blue 2': '#203C5D',
	'LAW Link Blue': '#00193C',
	'LAW Cream 1': '#FAF3E6',
	'LAW Cream 2': '#B1927B',
	'LAW Green 1': '#ABC7AB',
	'LAW Green 2': '#387956',
	'LAW Green 3': '#0B400A',
	'LAW Maroon': '#7A0040',
	'LAW Red': '#CF004F',
	'LAW Peach': '#FFA8A1',
	'Red Wine': '#7A0040',
	'White Wine': '#ffffff',
	'Rosé Wine': '#F59F9F',
	'Mixed Wine': '#011A3D',
	'Champagne & Sparkling': '#FFC700',
	Sparkling: '#FFC700',
	'Dessert Wine': '#011A3D',
	White: '#fff',
	'OSW Black': '#222222',
	'OSW White': '#ffffff',
	'OSW Grey 100': '#f8f8f8',
	'OSW Brown 1': '#7A0040',
	'OSW Red 1': '#CC2127',
	'OSW Maroon': '#7A0040',
};

const getColor = (color) => {
	return color && COLOR_CONSTANT[color];
};

export default getColor;
